import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";
import { MoneyFormat } from "../../../helpers/shippingHelpers";
import Loader from "../../../components/Loader/Loader";

import classNames from "classnames"
import { NavLink, useHistory } from "react-router-dom";


function PageMyOrdersPay() {
  const [profile, setProfile] = useState({})
  const [orderList, setOrderList] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState({ id: null });
  const [totalAmount, setTotalAmount] = useState(0);

  const [apiError, setApiError] = useState();

  const history = useHistory();
  
  
  const calculatePrice = orders => {
    if(orders.length) {
      const price =  MoneyFormat(orders.reduce((a,b) => a+Number(b.amount),0));
      setTotalAmount(price);
    } else {
      setTotalAmount();
    }
  }

  const getData = async () => {
    setLoading(true);
    const [profile, orders, methods] = await Promise.all([MyService.getProfile(), MyService.getOrderList({accepted: false}), MyService.getPaymentMethods()]);
    setProfile(profile);
    setOrderList(orders.orders);
    calculatePrice(orders.orders);
    setPaymentMethods(methods);
    setLoading(false);
  }

  const startPayment = async (e) => {
    e.preventDefault();
    const method = selectedMethod.id;
    setLoading(true);
    const requestData = { method, returnUrl: `${process.env.CONF_FRONTEND_HOST}/mijn/betalen/controleer`}
    const paymentData = await MyService.createPayment(requestData);
    if(paymentData.error) {
      setApiError(paymentData.error);
    }else {
      window.location = paymentData.paymentUrl;
    }
  }


  const acceptOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    const acceptRequest = await MyService.acceptPayment();

    if(acceptRequest.error) {
      setApiError(acceptRequest.error);
    }else {
      history.push("/mijn/betalen/labels");
    }
  }

  useEffect(() => {getData() }, [])


  return (
    <div>
      <Helmet>
        <title>
          Betalen - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />

        {loading || (!profile.id)? <Loader /> : 
        
          !orderList.length ? <>
            <div
              className="alert alert-warning alert-auto text-center w-100"
              role="alert"
            >
                  Je hebt geen opeenstaande zendingen om te betalen. <NavLink to="/mijn/orders/nieuw">Plaats nieuwe zending</NavLink>
            </div>
          </> : 
          
            <>
              {apiError && <div
                className="alert alert-warning alert-auto text-center w-100 my-5"
                role="alert"
              >
                {apiError.message}
              </div>}
              <Row className="mb-4">
          
            
                <Col xs={12} md={3}>
                  <h2 className=" mt-4">Orders</h2>
                  {orderList.map(order => `${order.number}, `)}
                </Col>
                <Col xs={12} md={6}>
                  <h2 className=" mt-4">Totaalbedrag</h2>
                  {totalAmount}
                </Col>

              </Row>

              {profile && profile.company && profile.company.allowAccept && (
                <> <Row className="boxed-grey mt-5 mb-5">
                  <Col xs={12} md={6}>
                    <h1 className="mt-2">Betaal op rekening</h1>
                    <p>Uw bedrijfsaccount is gemachtigd om te betalen op rekening</p>
              
                  </Col>
                  <Col  xs={12} md={6} className="align-self-center text-right">
                    <button
                      className={classNames("btn btn-primary")}
                      type="button"
                      onClick={acceptOrder}
                    >
              bevestig bestelling
                    </button>
                  </Col>
                </Row>
                <div className="hr-text hr-text--white  my-5"><span>of betaal direct online</span></div>
                
                </>)}

              <Row>
                <Col className="mt-4">
                  <h1 className="mb-4">Online betalen</h1>

                  {!paymentMethods.length && <Loader />}

                  {paymentMethods.length && paymentMethods.map((method) => (
                    <a
                      href="/#1"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedMethod({ id: method.id });
                      }}
                      className={classNames(
                        "orderpayment__method text-center",
                        {
                          "orderpayment__method--selected":
                            selectedMethod.id === method.id,
                        }
                      )}
                    >
                      <img src={method.svg} alt="" />
                      <span className="d-block w-100 text-center">
                        {method.name}
                      </span>

                    </a>
                  ))}
                </Col>
              </Row>

              <Row className="mt-3 mt-sm-5">
                <Col className="text-right">
                  <button
                    className={classNames("btn btn-primary", {
                      "btn-loading": loading,
                    })}
                    type="button"
                    disabled={!selectedMethod.id}
                    onClick={startPayment}
                  >
              betaal
                  </button>
                </Col>
              </Row>

            </>
        }
      </Container>
    </div>
  );
}

export default PageMyOrdersPay;
