import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "./OrderFlow.scss";
import Col from "react-bootstrap/Col";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import classNames from "classnames";

function PageOrderPayment() {
  const [orderStatus, setOrderStatus] = useState({ loading: true });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState({ id: null });

  const { orderId } = useParams();
  const dispatch = useDispatch();

  const getPaymentMethods = async () => {
    dispatch({ type: "CLEAR_ORDER" });

    try {
      const response = await Axios.get(
        `${process.env.CONF_API_BASE}/payment/methods`
      );
      setPaymentMethods((response.status === 200 && response.data) || []);

    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const getOrderStatus = async () => {
    try {
      const response = await Axios.get(
        `${process.env.CONF_API_BASE}/order/${orderId}`
      );
      setOrderStatus(
        (response.status === 200 && { ...response.data, loading: false }) || {
          loading: false,
        }
      );
    } catch (e) {
      return false;
    }
  };

  const startPayment = async (e) => {
    e.preventDefault();
    const method = selectedMethod.id;
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.CONF_API_BASE}/order/${orderId}/pay`,
        {
          method,
          returnUrl: `${process.env.CONF_FRONTEND_HOST}/betaling/controleer/${orderId}`,
        }
      );

      const url =
        response.status === 200 && response.data
          ? response.data.paymentUrl
          : false;
      window.location = url;
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    getPaymentMethods();
    getOrderStatus();
  }, []); //eslint-disable-line

  return (
    <div>
      <Helmet>
        <title>
          Betaling - verzenden.nl: één verzendplatform voor grote en kleine
          pakketten
        </title>
        <link
          rel="canonical"
          href="https://www.verzenden.nl/verzenden/betaal"
        />
      </Helmet>

      <Container className="mt-3 pt-5 ">
        <div className="container-content">
          <h1>Betaling</h1>
          <span className="order__subtitle">
            Na de betaling ontvang je direct je verzendlabel
          </span>
          {orderStatus.loading && <Loader />}

          {!orderStatus.loading && (
            <Col>
              <Row className="mt-4">
                <Col xs={6} sm={2} className="text-left">
                  Ordernummer:
                  <br />
                  Totaalbedrag:
                </Col>
                <Col xs={6} sm={8}>
                  <strong>{orderStatus.number}</strong>
                  <br />
                  <strong>&euro; {orderStatus && orderStatus.amount}</strong>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h2>Kies een betaalmethode</h2>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  {!paymentMethods.length && <Loader />}

                  {paymentMethods.map((method) => (
                    <a
                      href="/#1"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedMethod({ id: method.id });
                      }}
                      className={classNames(
                        "orderpayment__method text-center",
                        {
                          "orderpayment__method--selected":
                            selectedMethod.id === method.id,
                        }
                      )}
                    >
                      <img src={method.svg} alt="" />
                      <span className="d-block w-100 text-center">
                        {method.name}
                      </span>
                    </a>
                  ))}
                </Col>
              </Row>
              <Row className="mt-3 mt-sm-5">
                <Col className="text-right">
                  <button
                    className={classNames("btn btn-primary", {
                      "btn-loading": loading,
                    })}
                    type="button"
                    disabled={!selectedMethod.id}
                    onClick={startPayment}
                  >
                    betaal
                  </button>
                </Col>
              </Row>
            </Col>
          )}
        </div>
      </Container>
    </div>
  );
}

export default PageOrderPayment;
